import React, { useState } from "react";
import "./AppbarStyles.scss";
import { useSelector } from "react-redux";

import ArrowRight from "@material-ui/icons/ArrowForward";
import Mail from "@material-ui/icons/Mail";
import { IconButton } from "@material-ui/core";

import SideDrawer from "../SideDrawer";

const Appbar = ({ setContactModal }) => {
  const title = useSelector((state) => state.appBarTitle);
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div className='appbar-container '>
      <div className='appbar-inner-container'>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => setDrawerOpen(true)}>
            <ArrowRight
              fontSize='medium'
              className='brown-text drawer-button'
            />
          </IconButton>
          <span className='appbar-title brown-text'>{title}</span>
          <SideDrawer
            openContact={() => setContactModal(true)}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          />
        </div>
        <IconButton onClick={() => setContactModal(true)}>
          <Mail />
        </IconButton>
      </div>
    </div>
  );
};

export default Appbar;
