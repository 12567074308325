import React, { useEffect, useState } from "react";
import "./HomepageStyles.scss";
import {
  getClassNews,
  getClassNews2,
  getHomepageVideo,
} from "../../contentful";
import HomeHero from "../../components/HomeHero";
import NewsHero from "../../components/NewsHero";
import marked from "marked";

const Homepage = () => {
  const [classNews, setClassNews] = useState("");
  const [classNews2, setClassNews2] = useState("");
  const [videoCode, setvideoCode] = useState("");

  useEffect(() => {
    getClassNews().then((data) => setClassNews(data[0].fields.text));
    getClassNews2().then((data) => setClassNews2(data[0].fields.text));
    getHomepageVideo().then((data) => setvideoCode(data[0].fields.youtubeCode));
  }, []);
  return (
    <div>
      <HomeHero />
      <div className='page-contents'>
        <div className='two-columns'>
          <div className=' main-container two-columns__column'>
            <NewsHero />
          </div>
          {videoCode && (
            <div
              className='home-video-container two-columns__column'
              dangerouslySetInnerHTML={{ __html: marked(videoCode) }}
            />
          )}
        </div>
        <div className='two-columns'>
          {classNews && (
            <div className=' main-container two-columns__column'>
              <div className='line' />
              <h3 className='two-columns__title'>
                Termine Stuttgarter Posaunenklasse
              </h3>

              <div
                className='class-news-details'
                dangerouslySetInnerHTML={{ __html: marked(classNews) }}
              />
            </div>
          )}
          {classNews2 && (
            <div className=' main-container two-columns__column'>
              <div className='line' />
              <h3 className='two-columns__title'>
                Neues aus der Stuttgarter Posaunenklasse
              </h3>

              <div dangerouslySetInnerHTML={{ __html: marked(classNews2) }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
