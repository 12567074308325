import React, { useState, useEffect } from "react";
import { getNews } from "../../contentful";
import NewsItem from "../NewsItem";

const NewsHero = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getNews().then((data) => setNews(data));
  }, []);

  return (
    <div>
      {news.map((item, i) => (
        <NewsItem key={i} item={item} />
      ))}
    </div>
  );
};

export default NewsHero;
