import React, { useState, useEffect } from "react";
import "./MedienStyles.scss";
import { getYoutube } from "../../contentful";
import marked from "marked";

const Medien = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getYoutube().then((data) => setVideos(data));
  }, []);
  return (
    <div className='page-contents mediapage-container'>
      <div className='contents-container'>
        <div className='videos-container'>
          {videos.map((video) => (
            <div
              className='video-card'
              dangerouslySetInnerHTML={{
                __html: marked(video.fields.youtubeCode),
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Medien;
