import React from "react";
import "./RecordingCardStyles.scss";
import marked from "marked";

const RecordingCard = ({ recording }) => {
  return (
    <div className='recording-card-outer-container'>
      <div className='recording-card-contents'>
        <div className='recording-card-contents__image'>
          <img alt='cover' src={recording.cover.fields.file.url} />
        </div>
        <div className='recording-card-details'>
          <div style={{ marginTop: 0 }} className='line' />
          <div className='recording-card-details__text'>
            <h2 className='recording-card-details__title'>
              {" "}
              {recording.title}
            </h2>
            {recording.details && (
              <section
                className='recording-card-details__details'
                dangerouslySetInnerHTML={{ __html: marked(recording.details) }}
              />
            )}
          </div>
          <div className='audio-controls-container'>
            {recording.mp3 &&
              recording.mp3.map((track) => (
                <div className='audio-control'>
                  <span className='audio-control__text'>
                    {track.fields.title}
                  </span>

                  <audio className='audio-control__controller' controls>
                    <source src={track.fields.file.url} />
                  </audio>
                  <br />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingCard;
