import React from "react";
import "./ProgrammeItemStyles.scss";
import marked from "marked";

const ProgrammeItem = ({ programme }) => {
  return (
    <div className='main-container programme-card'>
      {programme.image && (
        <div className='programme-card__image'>
          <img alt='' src={programme.image.fields.file.url} />
        </div>
      )}
      <div className='programme-card-text'>
        <h1 className='programme-card-text__title'>{programme.title}</h1>
        <h4 className='programme-card-text__subtitle body-text'>
          {programme.subtitle}
        </h4>
        {programme.description && (
          <section
            className='body-text programme-card-text__details'
            dangerouslySetInnerHTML={{ __html: marked(programme.description) }}
          />
        )}
      </div>
    </div>
  );
};

export default ProgrammeItem;
