import React from "react";
import "./NewsItemStyles.scss";
import marked from "marked";

const NewsItem = ({ item }) => {
  const video = item.fields.youtubeCode
    ? marked(item.fields.youtubeCode)
    : null;

  const image =
    item.fields.image && !item.fields.youtubeCode ? (
      <img
        alt='news'
        src={item.fields.image.fields.file.url}
        className='news-item-image'
      />
    ) : null;

  const details = item.fields.details ? (
    <section
      className='news-item-text__details'
      dangerouslySetInnerHTML={{ __html: marked(item.fields.details) }}
    />
  ) : null;

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = new Date(item.fields.date);

  return (
    <div className='news-item'>
      <div className='news-item-text '>
        <div className='line' />
        {item.fields.date && (
          <p className='news-item-text__date'>
            {date.toLocaleDateString("de", options)}
          </p>
        )}
        {details}
      </div>
      {image ||
        (video && (
          <div className='news-item-image-container'>
            {image}
            <section
              className='news-item-video'
              dangerouslySetInnerHTML={{ __html: video }}
            />
          </div>
        ))}
    </div>
  );
};

export default NewsItem;
